import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "white",
        color: "black",
        border: "3px solid #ffbd2c",
        fontSize: "20px",
        padding: "10px",
        borderRadius: "10px",
        minWidth: "150px",
        height: "70px",
        margin: "20px",
        '&:hover': {
            backgroundColor: "#ffbd2c",
            color: "white",
        }
    },
}));

function Button(props) {
    const classes = useStyles()

    return (
        <button className={classes.container} onClick={props.onClick} {...props}>{props.name}</button>
    )
}

export default Button;
