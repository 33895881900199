import React from 'react';
import Button from './Button';
import useWindowSize from "../utils";
import MenuIcon from '@mui/icons-material/Menu';
import { styled, alpha } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const useStyles = makeStyles((theme) => ({
    navbar: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        listStyleType: "none",
        margin: 0,
        padding: 0,
        backgroundColor: "#008000",
        alignItems: "center",
        Zindex: "1", /* Stay on top of everything */
        top: "3.4em",      /* Stay at the top */
        borderBottom: "3px solid black",
        width: "100%",
    },
    right: {
        display: "flex",
        float: "right",
        marginLeft: "auto",
        marginRight: "30px",
    },
    main: {
        marginLeft: "20px",
        textDecoration: "none",
        color: "black"
    },
    white: {
        color: "white"
    }
}));

function Navbar() {
    const classes = useStyles()
    const { width } = useWindowSize();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        'None',
        'Atria',
        'Callisto',
        'Dione',
        'Ganymede',
        'Hangouts Call',
        'Luna',
        'Oberon',
        'Phobos',
        'Pyxis',
        'Sedna',
        'Titania',
        'Triton',
        'Umbriel',
    ];

    return (
        <div className={classes.navbar}>
            <a className={classes.main} href="/"><h1 style={{ color: "white" }}>Azienda agricola Morabito</h1> </a>

            <div className={classes.right}>
                {width > 1081 && (
                    <>
                        <a href="/chi-siamo"><Button name='Chi siamo' /></a>
                        <a href="/contatti"><Button name='Contatti' /></a>
                        <a href="/prodotti"><Button name='Prodotti' /></a>
                    </>
                )}
                {width <= 1080 && (
                    <>
                        <MenuIcon size="large"
                            color="inherit"
                            className={classes.white}
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick} />

                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            
                            <MenuItem onClick={handleClose}>
                                <a href="/chi-siamo" style={{textDecoration: "none"}}><b>Chi siamo</b></a>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <a href="/contatti" style={{textDecoration: "none"}}><b>Contatti</b></a>
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <a href="/prodotti"style={{textDecoration: "none"}}><b>Prodotti</b></a>
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </div>
        </div>
    );
}

export default Navbar;
