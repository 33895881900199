import React from 'react';

function NoMatch() {
    return (
        <>
            <h2>No Match</h2>
        </>
    );
}

export default NoMatch;