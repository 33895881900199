import React from 'react';
import { history } from './store';
import { Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Contacts from "./pages/Contacts";
import Who from "./pages/Who";
import NoMatch from "./pages/NoMatch";

import './App.css';

function App() {
  return (
    <Router history={history}>
      <Navbar />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/chi-siamo" component={Who} />
        <Route exact path="/contatti" component={Contacts} />
        <Route exact path="/prodotti" component={Products} />

        <Route component={NoMatch} />
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
